import styled from "styled-components";
import { colors } from "../../styles/colors";
import Button from "../../styles/button";
import { device } from "../../Device/Device";
import React, { useState } from "react";
import validator from "validator";
import axios from "axios";
import HubspotEmailForm from "./HubspotEmailForm";

function NewsLetter() {
  return (
    <>
      <NewsLetterContainer id="waitlist">
        <NewsLetterWrapper>
          <h1>STAY IN TOUCH</h1>
          <p>Get Updates on Devii's Commercialization.</p>
          <HubspotEmailForm
            region="na1"
            portalId="21871927"
            formId="b3c08a3d-288c-4d86-9c1c-7091fd1834ee"
          />
        </NewsLetterWrapper>
      </NewsLetterContainer>
    </>
  );
}

const NewsLetterContainer = styled.div`
  margin-top: 100px;
  border-top-right-radius: 380px;
  margin-right: 5%;
  @media ${device.mobileS} {
    h1 {
      font-size: 1.6rem;
    }
    p {
      font-size: 1rem;
    }
  }
  @media ${device.tabletS} {
    h1 {
      font-size: 1.8rem;
    }
    p {
      font-size: 1rem;
    }
  }

  @media ${device.laptopL} {
    h1 {
      font-size: 2rem;
    }
    p {
      font-size: 1rem;
    }
  }
`;
const NewsLetterWrapper = styled.div`
  color: ${colors.white};
  margin-left: 10%;
  margin-bottom: 60px;
  max-width: 400px;

  h1 {
    position: relative;
    &:before {
      content: "";
      position: absolute;
      top: -21.4px;
      height: 4px;
      background-color: ${colors.white};
      width: 50px;
    }
  }
  input[type="text"] {
    border: none;
    border-bottom: 2px solid ${colors.white};
    background: transparent;
    color: ${colors.white};

    ::placeholder {
      color: ${colors.white};
      font-style: italic;
    }
  }

  input:focus {
    outline: none;
  }

  @media ${device.mobileS} {
    p {
      min-width: 90%;
    }
    input[type="text"] {
      width: 240px;
    }
  }
  @media ${device.tabletS} {
    input[type="text"] {
      width: 320px;
    }
  }
  @media ${device.tablet} {
    input[type="text"] {
      align-self: end;
      height: 40px;
    }
  }
  @media ${device.laptop} {
    input[type="text"] {
      width: 410px;
    }
  }
`;
// const NewsLetterForm = styled.div`
//   display: flex;

//   padding-top: 12px;
//   @media ${device.mobileS} {
//     flex-direction: column;
//   }
//   @media ${device.tabletS} {
//     flex-direction: row;
//   }
//   @media ${device.tabletS} {
//     gap: 40px;
//   }
// `;

// const NewsletterButton = styled(Button)`
//   box-shadow: 2px 1000px 1px #0b014b inset;
//   @media ${device.mobileS} {
//     width: 200px;
//     margin-top: 40px;
//   }
//   @media ${device.tabletS} {
//     margin-top: 0;
//   }
// `;

const ErrorAlert = styled.p`
  color: red;
  font-size: 10px;
`;

export default NewsLetter;
