import React, { useState } from 'react';
import styled from 'styled-components';
import { colors } from './colors';
import { device} from '../Device/Device';


import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
interface StyledBurgerProps{
    open?: boolean
}


const Burger = () => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <StyledBurger open={open} onClick={() => setOpen(!open)}>
        <div />
        <div />
        <div />
        
      </StyledBurger>
    

    <Ul open={open}>

        <li onClick={() => setOpen(!open)}><Link to="/" >Home</Link></li>
        <li onClick={() => setOpen(!open)}><Link to="/careers">Careers</Link></li>
        

        {/*
        <li> <Link to="/pxdbcloud">products</Link></li>
        <li onClick={() => setOpen(!open)}><Link to="/about">About</Link></li>
        <li onClick={() => setOpen(!open)}> <Link to="/process">Process</Link></li>
        */}

    </Ul>
    </>
  )
}

export default Burger


const Ul = styled.ul<StyledBurgerProps>`
list-style: none;
display: flex;
flex-flow: row nowrap;
flex-flow: column nowrap;
background-color: #000000;
position: fixed;
z-index:1;
transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(100%)'};
top: -16px;
right:0;
height: 100vh;
width: 60vw;
padding-top: 3.5rem;
transition: transform 0.3s ease-in-out;

  
  li {
    padding: 18px 10px;
    text-transform: uppercase;
    color: ${colors.white};
  }
  a{
    color:white;
    text-decoration:none;
  }
`;

const StyledBurger = styled.div<StyledBurgerProps>`
  width: 2.5rem;
  height: 2.5rem;
  position: fixed;
  z-index:2;
  display: flex;
  justify-content: space-around;
  flex-flow: column nowrap;


  div {  
    background-color:white;
    width: 2.5rem;
    height: 0.25rem;
    border-radius: 10px;
    transform-origin: 1px;
    transition: all 0.3s linear;

    &:nth-child(1) {
        transform: ${({ open }) => open ? 'rotate(45deg)' : 'rotate(0)'};
    }
    &:nth-child(2) {
        transform: ${({ open }) => open ? 'translateX(-100%)' : 'translateX(0)'};
        opacity: ${({ open }) => open ? 0 : 1};
    }
    &:nth-child(3) {
        transform: ${({ open }) => open ? 'rotate(-45deg)' : 'rotate(0)'};
    }

  }


  @media ${device.mobileS} {
    width: 1.5rem;
    height: 1.5rem;
    top: 3.8%;
   right: 36px;
    div{
        width: 1.5rem;
        height: .1rem; 
    }
    



    @media ${device.laptop}{
        display: none;
    }
   
`;