import styled from "styled-components";
import Footer from "../Components/Footer/Footer";
import DarkBackground from "../Backgrounds/dark-background-2.png";
import logo from "../Graphics/Project_Phoenix_360x360.png";
import logo2 from "../Graphics/pxdb-logo-square-240x240.png";
//import logo2 from "../Graphics/Devii_logo_512.png";
import { useState, useEffect } from "react";

function Support() {
    const [logoImage, setLLogoImage ] = useState(false)
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <SupportContainer>
        <SupportImageContainer>
          <img onClick={() => setLLogoImage(prevMode => !prevMode)}
          width={500} 
          height={500} 
           src={logoImage ? logo2 : logo}
           alt="lightning-bolt">
          </img>
        </SupportImageContainer>
        <Footer />
      </SupportContainer>
    </>
  );
}

const SupportContainer = styled.div`
  background-image: url(${DarkBackground});
  background-size: cover;
`;

const SupportImageContainer = styled.div`
  padding-top: 15vh;
  height: 75vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export default Support;
