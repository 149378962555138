import "./App.css";
import Nav from "../src/Components/Nav/Nav";
import Home from "./Pages/Home";
import About from "./Pages/About";
import Support from "./Pages/Support";
import Careers from "./Pages/Careers";

// import Portfolio from "./Pages/Portfolio";
// import Process from "./Pages/Process";
// import Progress from "./Pages/Progress";
// import PXDBCloud from "./Pages/PXDBCloud";
// import Construction from "./Pages/Construction";
// import ComingSoon from "./Pages/ComingSoon";
import NewsLetter from "./Components/NewsLetter/NewsLetter";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  HashRouter,
  BrowserRouter,
} from "react-router-dom";
import { useEffect } from "react";
function App() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <HashRouter>
        <Nav></Nav>
        <Switch>
          <Route exact path="/">
            <About></About>
          </Route>
          <Route path="/support">
            <Support></Support>
          </Route>
          <Route path="/careers">
            <Careers></Careers>
          </Route>

          {/* 
          <Route exact path ="/"><Home></Home></Route>
          <Route path="/about"><About></About></Route>
          <Route path="/process">
            <Process></Process>
          </Route>
          <Route path="/progress">
            <Progress></Progress>
          </Route>
          <Route path="/pxdbcloud">
            <PXDBCloud></PXDBCloud>
          </Route>
          <Route path="/portfolio">
            <Portfolio></Portfolio>
          </Route>
          <Route exact path="/coming_soon">
            <ComingSoon></ComingSoon>
          </Route>
          */}
        </Switch>
      </HashRouter>
    </>
  );
}

export default App;
