import styled from "styled-components";
import { device } from "../Device/Device";
import Footer from "../Components/Footer/Footer";
import DarkBackground from "../Backgrounds/dark-background-2.png";
import logo from "../Graphics/Project_Phoenix_360x360.png";
import logo2 from "../Graphics/pxdb-logo-square-240x240.png";
import TeamContainerBackground from "../Backgrounds/TeamBackground-01.svg";
import Button from "../styles/button";
import opening from "../Data/openings.json";
//import logo2 from "../Graphics/Devii_logo_512.png";
import { useState, useEffect } from "react";

function Careers() {
  const [logoImage, setLLogoImage] = useState(false);

  const showOpenings = false;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const ApplyButtonHandler = (event: any) => {
    event.preventDefault();
    let URL = "";
    switch (event.target.id) {
      case "apply-front-end":
        console.log("case apply-front-end");
        URL = "https://www.linkedin.com/jobs/view/3108039926/";
        window.open(URL, "_blank");
        break;
      case "apply-back-end":
        URL = "https://www.linkedin.com/jobs/view/3153662438/";
        window.open(URL, "_blank");
        break;
    }
  };

  return (
    <>
      <div></div>
      <CareersContainer>
        {!showOpenings ? (
          <CareersHeroContainer>
            <CareersHeroTitle>
              <h1>Project Phoenix is currently</h1>
              <h1>
                <span>NOT</span> hiring!
              </h1>
            </CareersHeroTitle>
            <CareersCurrentOpenings>
              <h1>Current Openings</h1>
              <ProductStyledCardContainer>
                <div> There are currently no openings.</div>
              </ProductStyledCardContainer>
            </CareersCurrentOpenings>
          </CareersHeroContainer>
        ) : (
          <CareersHeroContainer>
            <CareersHeroTitle>
              <h1>Project Phoenix is </h1>
              <h1>
                <span>hiring!</span>
              </h1>
            </CareersHeroTitle>
            <CareersHeroContent>
              <p>
                We are looking for talented team members with a shared sense of
                ownership who enjoy working on projects from inception to
                scaling in production. If this describes you, please consider
                joining our team and assisting us in bringing our new SaaS
                software product, Devii, to market!
              </p>
            </CareersHeroContent>
            <CareersCurrentOpenings>
              <h1>Current Openings</h1>
              <ProductStyledCardContainer>
                <ProductStyledCard>
                <h2>Senior Software Engineer</h2>
                <h3>Front-End Engineering</h3>
                <h3>Fargo, ND</h3>
                <JobLink
                  href="/jd/Project_Phoenix_Front-end_Senior_Software_Engineer.pdf"
                  target="_blank"
                >
                  View Position Description
                </JobLink>
                <SmallNavButton
                  onClick={ApplyButtonHandler}
                  id="apply-front-end"
                >
                  APPLY ON LINKEDIN
                </SmallNavButton>
              </ProductStyledCard>
                <ProductStyledCard>
                <h2>Senior Software Engineer</h2>
                <h3>Back-End Engineering</h3>
                <h3>Fargo, ND</h3>
                <JobLink
                  href="/jd/Project_Phoenix_Back-end_Senior_Software_Engineer.pdf"
                  target="_blank"
                >
                  View Position Description
                </JobLink>
                <SmallNavButton
                  onClick={ApplyButtonHandler}
                  id="apply-back-end"
                >
                  APPLY ON LINKEDIN
                </SmallNavButton>
              </ProductStyledCard>
              </ProductStyledCardContainer>
            </CareersCurrentOpenings>
          </CareersHeroContainer>
        )}

        <Footer />
      </CareersContainer>
    </>
  );
}

const ProductStyledCardContainer = styled.ul`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  list-style-type: none;
  gap: 60px;
`;

const ProductStyledCard = styled.li`
  color: white;
  border: solid white 2px;
  border-radius: 16px;
  padding-left: 10px;
  padding-bottom: 10px;

  width: 300px;
  height: 225px;
  position: relative;

  h2 {
    padding-left: 10px;
    font-size: 1.2rem;
  }
  h3 {
    padding-left: 10px;
    font-size: 1rem;
    font-weight: normal;
  }
`;

const CareersContainer = styled.div`
  background-image: url(${DarkBackground});
  background-size: cover;
`;

const JobLink = styled.a`
  padding-left: 10px;
  color: white;
  text-decoration: underline;
  font-size: 1rem;
`;

const SmallNavButton = styled(Button)`
  margin-left: 50px;
  margin-top: 25px;
  font-size: 0.75rem;
  text-transform: uppercase;
  width: 160px;
  text-align: center;
  box-shadow: 2px 1000px 1px #0b014b inset;
`;

const CareersCurrentOpenings = styled.div`
  padding-top: 20px;
  color: white;
  h1 {
    font-size: 1rem;
    margin-bottom: 16px;
    text-align: left;
  }
`;

const CareersHeroContainer = styled.div`
position: relative;
margin-top: 10vh;
padding-left: 10%;
padding-top: 2px;
z-index: 1;  
min-height: 1000px;
&:after {
  position: absolute;
  content: "";
  opacity: 0.2;
  background: url(${TeamContainerBackground});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
}

  @media ${device.mobileS}{
      margin-right: 10%;
      h1{
          font-size: 1.6rem;
          margin-bottom: 16px;
          text-align: left;

      }
      p{
          font-size: 1rem;
          text-align: left;
          z-index: 2;
          line-height: 24px;
          
      }
  }
  @media ${device.tabletS}{
      h1{
        font-size: 1.8rem;
      }
      p{
          font-size:1rem;
          width: 370px;
      }
  }

  @media ${device.laptopL}{
      h1{
        font-size: 2rem;
      }
      p{
        font-size:1rem;
        width: 370px;
  }
  `;

const CareersHeroTitle = styled.h1`
  color: white;
  padding-top: 60px;
  z-index: 2;
  h1 {
    margin: 0;
  }
  &:before {
    position: absolute;
    content: "";
    width: 48px;
    height: 4px;
    background-color: white;
    margin-top: -8px;
  }
  span {
    color: #ff9933;
  }
`;

const CareersHeroContent = styled.div`
  color: white;
`;

export default Careers;
