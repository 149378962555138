import * as React from "react";

function SvgLogoPxdbFlat(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      id="Logo_PXDB_Flat_svg__Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 200 195.9"
      xmlSpace="preserve"
      width="3em"
      height="3em"
      {...props}
    >
      <style />
      <switch>
        <g>
          <path
            d="M100 14.4c47 0 85.3 38.3 85.3 85.3S147 185 100 185s-85.3-38.3-85.3-85.3S53 14.4 100 14.4m0-4.7c-49.7 0-90 40.3-90 90s40.3 90 90 90 90-40.3 90-90-40.3-90-90-90z"
            fill="white"
          />
          <path
            d="M72.4 90.9c-2.9 5.9-2.7 13.1 1.1 19 1.4 2.2 3.3 4 5.3 5.4l-.1.2c-4.6-3.1-8.6-7.2-11.8-12.1-3-4.5-4.9-9.4-6-14.4-3.7-17 3.2-35.3 18.7-45.3 15-9.7 33.8-9 47.8.4l.1-.2c-22.2-15.1-52.2-16.3-76.1-.7C44 48 37.8 53.9 32.9 60.7c5.9-10.3 14.3-19.5 25-26.4 36-23.3 84-13.1 107.4 22.8s13.1 84-22.8 107.4C111.1 184.9 70.3 179.7 45 154c16.3 13.5 38.1 18.7 58.7 14.1 7.8-13.6 9.3-30.2 3.6-45.2-1.2-3.2-2.8-6.3-4.7-9.3-.1-.2-.3-.4-.4-.6-7.3-11.2-18-18.7-29.8-22.1z"
            fill="white"
          />
        </g>
      </switch>
    </svg>
  );
}

export default SvgLogoPxdbFlat;
