import Footer from "../Components/Footer/Footer";
import styled from "styled-components";
import Background from "..Backgrounds/dark-backgound.png";
import DarkBackground from "../Backgrounds/dark-background-2.png";
import AboutHero from "../Components/AboutHero/AboutHero";
import AboutProjectPhoenix from "../Components/AboutProjectPhoenix/AboutProjectPhoenix";
import AboutTeam from "../Components/AboutTeam/AboutTeam";
import NewsLetter from "../Components/NewsLetter/NewsLetter";
import { useEffect } from "react";
function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <AboutContainer>
        <AboutHero />
        <AboutTeam />
        <NewsLetter />
        <Footer />
      </AboutContainer>
    </>
  );
}

export default About;

const AboutContainer = styled.div`
  background-image: url(${DarkBackground});
  background-size: cover;
`;
