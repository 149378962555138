
import styled from 'styled-components';
import { device } from '../../Device/Device';
import { colors } from '../../styles/colors';
import SvgPhoenix from './logo/Phoenix';
import Burger from '../../styles/menu';
import StyledLink from '../../styles/link';
import StyledLink2 from '../../styles/link2';
import SvgLogoPxdbFlat from './flat_logo/LogoPxdbFlat';
import SvgProjectPhoenixNav05 from "./NavLogo/ProjectPhoenixNav05";
import Button from '../../styles/button';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";
  import { useLocation } from "react-router-dom";
function Nav(){
    //assigning location variable
    const location = useLocation();

    //destructuring pathname from location
    const { pathname } = location;

    //Javascript split method to get the name of the path in array
    const splitLocation = pathname.split("/");
    return(
        <>
            <NavContainer>
                <NavLogo> 
                 
                   <SmallLogo>
                   <Link to="/">
                    <SvgLogoPxdbFlat/>
                    </Link>
                    </SmallLogo>

                    <LargeLogo>
                    <Link to="/">
                    <SvgPhoenix/>
                    </Link>
                    </LargeLogo>
                 
                </NavLogo>


                <NavLinkContainer>
                  
                    <NavLink>
                    <li className={splitLocation[1] === "careers" ?  "active" : ""}><Link to="/careers"><StyledLink2>Careers</StyledLink2></Link></li>
                    <li><a href="https://devii.io" target="_blank">Devii</a></li>
                    
                        {/*
                        <li className={splitLocation[1] === "about" ?  "active" : ""}><Link to="/about"><StyledLink2>About</StyledLink2></Link></li>
                        <li className={splitLocation[1] === "process" ? "active" : ""}> <Link to="/process"><StyledLink2>process</StyledLink2> </Link></li>
                        <li> <Link to="/portfolio"> <StyledLink2>portfolio</StyledLink2></Link> </li>
                      
                        <li> <Link to="/pxdbcloud"><StyledLink2>products</StyledLink2></Link></li>
                         */}
                        <li><NavButton ><a href="https://outlook.office365.com/owa/calendar/Bookings@projectphoenix.io/bookings/" target="_blank">GET IN TOUCH </a></NavButton></li>
                    </NavLink>
                    
                    <SmallNavButton> <a href="https://outlook.office365.com/owa/calendar/Bookings@projectphoenix.io/bookings/" target="_blank">GET IN TOUCH </a></SmallNavButton>
                    
                    <Burger></Burger>
                    
                   
                </NavLinkContainer>
            </NavContainer>
        </>
    )
}

const NavContainer = styled.div`

display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
color: ${colors.white};
background-color: #000000;
height: 10vh;
/*
box-shadow: inset 0px -1px 0px 0px ${colors.white}, 0px 0px 20px 0px #20202088 ;
*/
z-index: 3;
position: relative;
@media ${device.mobileS} {
    position: fixed;
    top:0;
    width: 100%;
  }
a{
    color:white;
    text-decoration: none;
}

`
const NavLogo = styled.div`

display: flex;
flex-direction: row;
align-items: baseline;

margin-left: 5%;


@media ${device.mobileS} {
    width: 50px;

  }
a{
   
}

`

const SmallLogo = styled.div`
@media ${device.tablet}{
    display:none;
}
`

const LargeLogo = styled.div`

@media ${device.mobileS}{
display:none;
}
@media ${device.tablet}{
    display:unset;
    display: inline-block;
    
}
`

const NavLinkContainer = styled.div`
display: flex;
flex-direction: row;
justify-content: flex-end;
margin-right: 32px;
@media ${device.laptopL}{
   
}
`

const NavLink = styled.ul`
display: flex;
flex-direction: row; 
list-style-type: none;
gap: 34px;
text-transform: uppercase;
color:white;
align-items: baseline;
a{
    color:white;
text-decoration:none;

}
li{
    color:white;
    letter-spacing: 1px;
    font-weight: 700;
    position:relative;
    &.active{
        position:relative;
        &:before{
            content:"";
            position: absolute;
            bottom: -8px;
            left:0;
            width: 100%;
            height:2px;
            background-color: white;
            transition: all 0.5s;
            transform: scale(1);
            transform-origin: left;
        
        }
    }
  
}
@media ${device.mobileS}{
    display: none;
    
}

@media${device.laptop}{
    display: unset;
    display: flex;
    flex-direction: row; 
    list-style-type: none;
    text-transform: uppercase;
    align-items: baseline;
    gap: 20px;
}

`


const NavButton = styled(Button)`
min-width: 155px;
`

const SmallNavButton = styled(Button)`
font-size: 12px;
background-color: ${colors.primary};
text-transform: uppercase;
width:140px;
margin-right: 54px;
@media ${device.mobileS}{
    display:none
}
@media ${device.mobileM}{
    display:unset;
}
@media ${device.laptop}{
    display:none
}
`



export default Nav;
