import styled from "styled-components";
import LeftImage from "./work1.jpg";
import RightImage from "./work2.jpg";
import { device } from '../../Device/Device'
import AboutGraphic from "../../Graphics/About-Graphic.png"
function AboutHero() {
	return (
		<>
			<AboutHeroContainer>
				<AboutHeroTitle>
					<h1>Project Phoenix is on</h1>
					<h1><span>your team</span></h1>
				</AboutHeroTitle>
				<AboutHeroContent>
					<p>Project Phoenix empowers engineers to do what they do best - create more solutions in less time, leaving you free to keep honing your team's skillset at solving problems.</p>
					<AboutHeroImageContainer>
						<img src={AboutGraphic}></img>
					</AboutHeroImageContainer>
				</AboutHeroContent>

			</AboutHeroContainer>

		</>
	)
}

const AboutHeroContainer = styled.div`

position: relative;
margin-top: 10vh;
padding-left: 10%;
padding-top: 2px;

@media ${device.mobileS}{
    margin-right: 10%;
    h1{
        font-size: 1.6rem;
        margin-bottom: 16px;
        text-align: left;

    }
    p{
        font-size: 1rem;
        text-align: left;
        z-index: 2;
        line-height: 24px;
        
    }
}
@media ${device.tabletS}{
    h1{font-size: 1.8rem;
      
        }
        p{
            font-size:1rem;
            width: 370px;
        }
}

@media ${device.laptopL}{
    h1{
        font-size: 2rem;
    
    }
    p{font-size:1rem;
        width: 370px;
}
`
const AboutHeroTitle = styled.h1`
color: white;
padding-top: 60px;
z-index: 2;
h1{
    margin: 0;
}
&:before{
position: absolute;
content:"";
width:48px;
height: 4px;
background-color:white;
margin-top: -8px;
}
span{
    color: #ff9933;
}
`
const AboutHeroImageContainer = styled.div`
display: flex;
width: 100%;
z-index: 0;
img{
    width: 100%;
    z-index:0;
    
   
}
@media ${device.mobileS}{
    margin-bottom: 50px;
}
@media ${device.laptop}{
    transform: translateY(-200px);
    margin-bottom:0;
}
`

const AboutHeroContent = styled.div`
color: white;


`
export default AboutHero;