import styled from "styled-components"

const StyledLink2 = styled.a `
display: inline-block;
position: relative;


cursor:pointer;
&:before{
    content:"";
    position: absolute;
    bottom: -8px;
    left:0;
    width: 100%;
    height:2px;
    background-color: white;
    transition: all 0.5s;
    transform: scale(0);
    transform-origin: left;

}

&:hover:before{
    transform: scale(1);

}
  
`
export default StyledLink2