import styled from "styled-components"
import { colors } from "./colors"
import Gradient from "./gradient-background.png";

const Button = styled.button`
cursor:pointer;
color: ${colors.white};
border: solid 3px transparent;
background-image: url(${Gradient});
background-origin: border-box;
background-clip: content-box,
background-size: cover;
background-repeat: no-repeat;
background-position: 45% center;
border-radius: 100rem;
font-size: 1rem;
padding: .5rem 1rem;
font-weight: 700;
border-box;
  box-shadow: 2px 1000px 1px #000000 inset;
`

export default Button