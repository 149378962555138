import styled from "styled-components";
import { colors } from "../../styles/colors";
import { FaLinkedin } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";
import { device } from "../../Device/Device";
import StyledLink2 from "../../styles/link2";
import Button from "../../styles/button";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
function Footer() {
  const companyName = "Project Phoenix Technologies, Inc.";
  const currentYear = new Date().getFullYear();

  return (
    <>
      <FooterContainer>
        <FooterContact>
          <h2>Let's Talk</h2>

          <FooterButton>
            {" "}
            <a
              href="https://outlook.office365.com/owa/calendar/Bookings@projectphoenix.io/bookings/"
              target="_blank"
            >
              GET IN TOUCH
            </a>
          </FooterButton>

          <p>
            1854 NDSU Research Park Dr N #209<br />
            Fargo, North Dakota 58102
          </p>
          <p>contact@projectphoenix.io</p>
          <SvgContainer>
            <LinkIcon
              href="https://www.linkedin.com/company/project-phoenix-fargo"
              target="_blank"
            >
              <FaLinkedin></FaLinkedin>
            </LinkIcon>
            <LinkIcon href="mailto:contact@projectphoenix.io" target="_blank">
              <HiOutlineMail></HiOutlineMail>
            </LinkIcon>
          </SvgContainer>
        </FooterContact>

        <FooterLink>
          <h2>Explore</h2>
          <LinkList>
            <div>
              <ul>
                <li>
                  {" "}
                  <Link to="/">
                    {" "}
                    <StyledLink2>Home</StyledLink2>
                  </Link>
                </li>
                <li>
                  {" "}
                  <Link to="/careers">
                    {" "}
                    <StyledLink2>Careers</StyledLink2>
                  </Link>
                </li>
                <li>
                  {" "}
                  <a href="https://devii.io" target="_blank">
                    {" "}
                    <StyledLink2>Devii</StyledLink2>
                  </a>
                </li>
                
                {/*
                <li>
                  {" "}
                  <Link to="/process">
                    <StyledLink2>Process</StyledLink2>
                  </Link>
                </li>
                    <li> <Link to="/portfolio"><StyledLink2>Portfolio</StyledLink2></Link></li>
                     <li><Link to="/pxdbcloud"><StyledLink2>Products</StyledLink2></Link></li>
                     <li>
                  <Link to="/about">
                    <StyledLink2>About</StyledLink2>
                  </Link>
                </li>
                    
                    */}
                
              </ul>
            </div>
          </LinkList>
        </FooterLink>
      </FooterContainer>
      <FooterCopyrightContainer>
        <p>{`Brought to you and powered by ${companyName} © ${currentYear}. All rights reserved.`}</p>
      </FooterCopyrightContainer>
    </>
  );
}

const FooterContainer = styled.div`
  min-height: 20vh;
  display: flex;
  padding-top: 100px;
  color: white;
  @media ${device.mobileS} {
    flex-direction: column-reverse;
  }
  @media ${device.tabletS} {
    flex-direction: row;
  }
`;
const FooterContact = styled.div`
  flex: 1;
  text-align: left;
  margin-left: 10%;
  h2 {
    margin-bottom: 15px;
  }
  p {
    font-size: 12px;
  }
`;
const FooterButton = styled(Button)`
  box-shadow: 2px 1000px 1px #0b014b inset;
  a {
    color: white;
    text-decoration: none;
  }
`;
const SvgContainer = styled.div`
  display: flex;
  gap: 16px;
  font-size: 24px;
`;
const LinkIcon = styled.a`
  cursor: pointer;
  color: white;
  text-decoration: none;
  &:hover {
    color: ${colors.orange};
  }
`;

const FooterLink = styled.div`
  flex: 2;
  text-align: left;
  display: flex;
  flex-direction: column;
  h2 {
    margin-bottom: 15px;
  }
  @media ${device.mobileS} {
    margin-left: 10%;
  }
  @media ${device.laptop} {
    margin: 0;
  }
`;

const LinkList = styled.div`
  ul {
    list-style: none;
    text-align: left;
    padding: 0;
    display: flex;
    flex-direction: column;
  }
  li {
    padding-bottom: 26px;
    cursor: pointer;
    display: inline-block;
  }
  a {
    color: white;
    text-decoration: none;
  }

  @media ${device.mobileS} {
    li {
      width: 50%;
    }
  }
  @media ${device.laptop} {
    li {
      width: 30%;
    }
  }
`;

const FooterCopyrightContainer = styled.div`
  color: white;

  border-style: none;
  text-align: left;
  padding-top: 50px;
  padding-bottom: 30px;
  p {
    margin-left: 10%;
    margin-right: 10%;
    border-style: none;
    font-size: 11px;
    color: #789;
  }
`;
export default Footer;
