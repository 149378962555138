import styled from "styled-components";
import { device } from "../../Device/Device";
import { FaLinkedin } from "react-icons/fa";
import TeamContainerBackground from "../../Backgrounds/TeamBackground-01.svg";
import team from "../../Data/team.json";
interface teamRecord {
  id: string;
  first_name: string;
  last_name: string;
  title: string;
  active: boolean;
  team_picture: string;
  linkedIn: string;
}

let team_sort_active = team.sort((a, b) =>
  a.last_name.toLowerCase() > b.last_name.toLowerCase() ? 1 : -1
);
team_sort_active = team_sort_active.filter((element, index) => {
  return element.active;
});

function AboutTeam() {
  return (
    <>
      <AboutTeamContainer>
        <TeamHeaderContainer>
          <TeamHeader>
            <h1>MEET OUR TEAM</h1>
          </TeamHeader>
        </TeamHeaderContainer>
        <AboutTeamContent>
          <div>
            <p>
              Our team has been where you are and we get it! The burnout from
              boring, repetitive tasks is something we all deal with, especially
              when it comes to writing code (like APIs).{" "}
            </p>
            <p>
              That's why we built Devii, so you can write the code that really
              matters —code for your projects, for your team, and for your
              customers— and cut down the burnout.
            </p>
          </div>
        </AboutTeamContent>
        <TeamGridContainer>
          {team_sort_active.map((teamMember) => (
            <GridTeamItem key={teamMember.id}>
              <img src={teamMember.team_picture}></img>
              <a href={teamMember.linkedIn} target="_blank">
                <LinkedInItem></LinkedInItem>
              </a>
              <h3>
                {teamMember.first_name} {teamMember.last_name}
              </h3>
              <p>{teamMember.title}</p>
            </GridTeamItem>
          ))}
        </TeamGridContainer>
      </AboutTeamContainer>
    </>
  );
}

const AboutTeamContainer = styled.div``;

const TeamHeaderContainer = styled.div`
  display: flex;
  margin-left: 10%;
  @media ${device.laptop} {
    margin-left: 28%;
  }
`;

const TeamHeader = styled.h1`
  color: white;
  text-align: center;
  position: relative;
  display: inline-block;
  @media ${device.mobileS} {
    h1 {
      font-size: 1.6rem;
      margin: 0;
    }
  }
  @media ${device.tabletS} {
    h1 {
      font-size: 1.8rem;
    }
  }

  @media ${device.laptopL} {
    h1 {
      font-size: 2rem;
    }
  }
`;
const AboutTeamContent = styled.div`
  color: white;
  position: relative;
  div {
    display: inline-block;
    text-align: left;
    line-height: 80%;
    &:before {
      content: "";
      position: absolute;
      background-color: white;
      height: 3px;
      width: 60px;
      top: 0;
    }
  }

  @media ${device.mobileS} {
    margin-left: 10%;
    margin-right: 10%;
    p {
      font-size: 1rem;
      line-height: 24px;
    }
  }
  @media ${device.tabletS} {
    font-size: 1rem;
  }
  @media ${device.laptop} {
    margin-left: 28%;
    p {
      font-size: 1rem;
      width: 400px;
      line-height: 24px;
    }
  }
`;
const TeamGridContainer = styled.div`
  display: grid;
  justify-content: center;
  column-gap: 32px;
  row-gap: 60px;
  position: relative;
  z-index: 1;
  @media ${device.laptopL} {
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: -1;
      opacity: 0.2;
      background: url(${TeamContainerBackground});
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 50% 50%;
    }
  }

  padding-top: 100px;
  padding-bottom: 100px;
  @media ${device.mobileS} {
    grid-template-columns: repeat(auto-fill, 260px);
  }
  @media ${device.laptop} {
    grid-template-columns: repeat(3, 260px);
  }
`;

const GridTeamItem = styled.div`
  position: relative;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  color: white;
  padding-top: 32px;
  h3 {
    margin-bottom: 8px;
    padding-left: 8px;
  }
  p {
    margin: 0;
    position: relative;
    padding-left: 16px;
    font-style: italic;
    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 2px;
      background-color: #e87647;
      margin-left: 9px;
    }
  }
  a {
    height: 300px;
    width: 100%;
  }
  img {
    height: 300px;
    width: 100%;
    border-radius: 16px;
    object-fit: cover;
    object-position: top;
  }
`;

const LinkedInItem = styled(FaLinkedin)`
  color: white;
  position: absolute;
  font-size: 1.4rem;
  right: 10px;
  bottom: 44px;
`;
export default AboutTeam;
